import React from 'react'
import { useThemeStore } from '../stores/themeStore'

type Props = {
    children: React.ReactNode
}

const ThemeWrapper = (props: Props) => {
    const {isDarkMode} = useThemeStore ();

    const style: any = {
        backgroundColor: isDarkMode ? "#121212" : "white",
        color: isDarkMode ? "white": "black",
    };
  return (
    <div style={style}>{props.children}</div>
  )
}

export default ThemeWrapper