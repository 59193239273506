import { useEffect, useRef, useState } from 'react'
import Row from '../../common/Row'
import Container from '../../common/Container'
import { WorkExperienceModel } from '../../models/WorkExperienceModel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown} from '@fortawesome/free-solid-svg-icons'
import { animated, useSpring } from '@react-spring/web'
import { useMediaQuery } from 'react-responsive'

type Props = {
    workExp: WorkExperienceModel
}

const OrgNameWithDate = (props: Props) => {
    ///component should be named as work exp projects

    const [shouldShow, setShow] = useState(false);
    const contentRef = useRef<HTMLUListElement>(null);
    const [contentHeight, setContentHeight] = useState(0);
    const isMobile = useMediaQuery({ maxWidth: 1100 });

    const expandAnimation = useSpring ({
        height: shouldShow ? `${contentHeight}px` : "0px",
        opacity: shouldShow ? 1 : 0,
        overflow: "hidden",
    });

    useEffect(() => {
        if (contentRef.current) {
            setContentHeight(contentRef.current.scrollHeight);
        }
    }, [shouldShow]);

    return (
        <div >
            <div onClick={() => { setShow(!shouldShow) }} style={{ cursor: "pointer" }}>
                <Container padding={isMobile ?  '0 30px': "0 10px"}>
                    <Row alignItems='center' justifyConent='space-between'>
                        <h3>{props.workExp.orgName}</h3>
                        <div style={{width: isMobile ? "20%": "60%" }}></div>
                        {isMobile && <h5>{props.workExp.startEndDate}</h5>}
                        {!isMobile && <h3>{props.workExp.startEndDate}</h3>}
                        <FontAwesomeIcon
                            icon={faChevronDown}
                            style={{
                                transition: 'transform 0.5s ease',
                                transform: shouldShow ? 'rotate(180deg)' : 'rotate(0deg)',
                            }}
                        />
                    </Row>
                </Container>
            </div>
            <animated.div style={expandAnimation} >
            <ul ref={contentRef}>
                {props.workExp.projects.map((p, index) => (<li key={index}>
                    <strong>{p.title + ": "}</strong>
                    {p.description}
                    <br></br>
                    <a href={p.link} target='blank'>App Store Link</a>
                    <br></br>
                    <br></br>
                </li>))}
            </ul>
            </animated.div>
        </div>
    )
}

export default OrgNameWithDate