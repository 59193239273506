import {useEffect} from 'react'
import Card from '../../common/Card'
import Column from '../../common/Column'
import Container from '../../common/Container'
import OrgNameWithDate from './OrgNameWithDate'
import { useWorkExpreinceStore } from '../../stores/WorkExperinceStore'
import { useMediaQuery } from 'react-responsive'

type Props = {
}

const Experience = (props: Props) => {
  const isMobile = useMediaQuery({ maxWidth: 1100});
  const {workExperiences, getWorkExperiences, isLoading} = useWorkExpreinceStore ();

  useEffect (() => {
    getWorkExperiences ();
  }, [getWorkExperiences])

  
    if (!isLoading) {
      console.log (workExperiences[0].id);
    }
    if (isLoading) return (<div>loading...</div>)
  return (
    <div>
        <Card margin={isMobile ? "5vh 3vw 0 3vw" : '0 0 5vh 15vw'}>
            <Column>
            <Container width= {isMobile ? "94vw" : '66.5vw'} margin='160 0 0 0px' padding={isMobile ? "0px" : '30px'}>
            <h2 style={{marginLeft: isMobile ? "5vw" : "0px"}}>Work Experience</h2>
            {workExperiences.map ((workExp, index) => (
              <OrgNameWithDate workExp={workExp} key={index}></OrgNameWithDate>
              
            ))}
            </Container>
            </Column>
        </Card>
    </div>
  )
}

export default Experience