import './ColoredSkill.css';

type Props = {
  backgroundColor: string;
  title: string;
};

const ColoredSkill = (props: Props) => {
  // Define the shake animation using keyframes in JS
  const shakeAnimation = {
    animation: 'bubble 2s ease-in-out infinite',
  };

  const style: any = {
    backgroundColor: props.backgroundColor,
    padding: "7px 12px",
    margin: "5px",
    display: "inline-block",
    borderRadius: "25px",
    ...shakeAnimation, // Add shake animation to the style
  };

  return <p style={style} className='shake-animation'>{props.title}</p>;
};



export default ColoredSkill;
