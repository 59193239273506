import { wrap } from 'module';
import React from 'react'

type Props = {
    children: React.ReactNode,
    alignItems?: string,
    wrap?: string,
    justifyConent?: string  
}

const Row = (props: Props) => {

    const style: any = {
        display: "flex",
        flexDirection: "row",
        flexWrap: props.wrap,
        alignItems: props.alignItems,
        justifyContent: props.justifyConent
    }; 
  return (
    <div style={style}>{props.children}</div>
  )
}

export default Row