import React from 'react'
import { useThemeStore } from '../stores/themeStore';

type Props = {
    alignItems?: string,
    children: React.ReactNode,
    marginLeft?: string
}

const Column = (props: Props) => {
    const { isDarkMode } = useThemeStore();
    const style: any = {
        display: "flex",
        flexDirection: "column",
        marginLeft: props.marginLeft,
        alignItems: props.alignItems
    }; 
  return (
    <div style={style} >{props.children}</div>
  )
}

export default Column