import React, {useEffect} from 'react'
import Column from '../../common/Column'
import Header from '../../shared/header/Header'
import { useThemeStore } from '../../stores/themeStore'
import Container from '../../common/Container'
import Row from '../../common/Row'
import Skills from '../home/Skills'
import AboutMeCard from '../home/AboutMeCard'
import ThemeWrapper from '../../common/ThemeWrapper'
import ProjectThumbnail from './ProjectThumbnail'
import { useProjectStore } from '../../stores/ProjectsStore'

type Props = {}

const ProjectsPage = (props: Props) => {

    const {isLoading, projects, getProjects} = useProjectStore ();

    useEffect ( () => {
        getProjects ();
    }, [getProjects]);
    
    if (!isLoading) {
        console.log (projects[0].title);
    }
    if (isLoading) {
        return (<div>data is loading...</div>);
    }
    return (
        <div>
            <ThemeWrapper>
            <Column>
                <Header></Header>
                <Container width='100vw'
                margin='50px 0 0 135px'
                >
                <Row wrap='wrap'>
                    {projects.map ( (project, index) => (<ProjectThumbnail project={project} key={index}></ProjectThumbnail>)) }
                </Row>
                </Container>
            </Column>
            </ThemeWrapper>
        </div>
    )
}

export default ProjectsPage