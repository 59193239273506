import React, { useState } from 'react'

type Props = {
  isDarkMode: boolean
}

const GetInTouchBtn = (props: Props) => {

  const [isHovering, setIsHovering] = useState(false);

    function handleIsHovering(): void {
        setIsHovering(true);
    }

    function handleNotHovering(): void {
        setIsHovering(false);
    }

  const style: any = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    height: "20px",
    width: "120px",
    border: props.isDarkMode ? "1px solid white" : "1px solid lightGrey",
    color: props.isDarkMode ? "white" : "black",
    padding: "10px",
    marginLeft: "auto",
    backgroundColor: isHovering ? (props.isDarkMode ? "#3a3a3a" : "#f0f0f0"): "transparent"
  };

  return (
    <div style={style} onMouseEnter={handleIsHovering} onMouseLeave={handleNotHovering}>
      Get In Touch
    </div>
  )
}

export default GetInTouchBtn