import React from 'react'
import { useThemeStore } from '../../stores/themeStore';
import DropDownItem from './DropDownItem';
import {FaFileDownload, FaHome, FaMailBulk, FaUser } from 'react-icons/fa';

type Props = {}

const DropDown = (props: Props) => {
    const {isDarkMode} = useThemeStore ();
    const text: string = "Welcome to my personal space on the web. Discovery my skills, passions and journey";
    const style: any = {
         width: "30rem", backgroundColor: !isDarkMode ? "white": "black", position: "absolute", marginTop: "5px",
        borderRadius: "15px", display: "flex", flexDirection: "column", border: isDarkMode ? "none": "1px solid black", zIndex: "1000"
    }

  return (
    <div style={style}>
        <DropDownItem title='Home' icon={FaHome} text={text}></DropDownItem>
        <DropDownItem title='About' icon={FaUser} text={text}></DropDownItem>
        <DropDownItem title='Contact' icon={FaMailBulk} text={text}></DropDownItem>
        <DropDownItem title='Resume' icon={FaFileDownload} text={text}></DropDownItem>
    </div>
  )
}

export default DropDown