import React, { useState } from 'react'
import { useThemeStore } from '../../stores/themeStore';
import { FaArrowAltCircleDown, FaArrowDown, FaArrowUp, FaChevronDown } from 'react-icons/fa';
import DropDown from './DropDown';

type Props = {}

const HomeButton = (props: Props) => {
    const {isDarkMode} = useThemeStore ();
    const [isOpen, setIsOpen] = useState (false);

    function handleIsOpen () {
        setIsOpen (!isOpen);
    }

    const style: any = {
        color: isDarkMode ? "white" : "black",
        paddingLeft: "2.5rem",
        paddingRight: "2.5rem",
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    };

  return (
    <div>
    <h4 style={style} onClick={handleIsOpen}>Home<FaChevronDown style={{paddingTop: "3px", paddingLeft: "10px"}}></FaChevronDown></h4>
    {isOpen && (<DropDown></DropDown>)}
    </div>
  )
}

export default HomeButton