
import './App.css';
import HomePage from './pages/home/HomePage';
import { BrowserRouter as Router, Route, Routes, BrowserRouter } from 'react-router-dom'; // Ensure you're using BrowserRouter
import ProjectsPage from './pages/projects/ProjectsPage';
import ProjectDetailsPage from './pages/projects/ProjectDetailsPage';

function App() {

  return (
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<HomePage/>} />
      <Route path="/projects" element={<ProjectsPage/>} />
      <Route path="/projectDetails" element={<ProjectDetailsPage/>} />
    </Routes>
    </BrowserRouter>
  );
}

export default App;
