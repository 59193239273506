import React from 'react'
import { useThemeStore } from '../../stores/themeStore';
import GetInTouchBtn from './GetInTouchBtn';
import { useMediaQuery } from 'react-responsive';

type Props = {}

const ProfileAvatar = (props: Props) => {

    const {isDarkMode} = useThemeStore ();
    const isMobile = useMediaQuery({ maxWidth: 1100});
    
    const profileAvatarStyle: any = {
        display: "flex",
        flexDirection: "column",
        border: isDarkMode ? "1px solid white" : "1px solid lightGrey",
        borderRadius: "10px",
        margin: isMobile ? "4vh 3vw" : "5vh 15vw",
        padding: isMobile ? "10px" : "2vh 2vw",
        color: isDarkMode ? "white": "black",
    };

    const imgStyle = {
        height: isMobile ? "50px" : "80px",
        width: isMobile ? "50px" : "80px",
        borderRadius: isMobile ? "25px" : "40px"
    };


  return (
    <div style={profileAvatarStyle}>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
            
        <img style={imgStyle} src='https://media.licdn.com/dms/image/v2/D4D03AQGGGW9Joq7CRQ/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1675376538805?e=1732752000&v=beta&t=VBPivOX2xAHJOaU-sOA33c4NbCKBQO4lKaaOGhhf1-c'></img>
        <div style={{width: "1vw"}}></div>
        <h2>Muhammad Khan Awan</h2>
        </div>
        <h2>Senior Flutter Developer</h2>
        <p>I'm a senior Flutter developer (you can call me Flutter fanatic), with a background of multinational corporates, boasting over <strong> 4+ years experience </strong>in this domain. My journey with Flutter includes working on  <strong>20+ diverse apps </strong>. Created <strong>10+  </strong>products from ground up. This spans a wide array from ecommerce to Uber-esque ride-booking apps & from multimedia streaming services akin to Netflix/Spotify to brief video-sharing solutions like TikTok. My role has varied across projects, ranging from building some apps entirely from scratch to debugging others and even developing new features in certain apps.</p>
        <GetInTouchBtn isDarkMode={isDarkMode}></GetInTouchBtn>
    </div>
  )
}

export default ProfileAvatar