import {initializeApp} from "firebase/app";
import {getFirestore} from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDHOwty2wkzdZgDHryp1hyZWrQW-UXqxV4",
    authDomain: "portfolio-f7140.firebaseapp.com",
    projectId: "portfolio-f7140",
    storageBucket: "portfolio-f7140.appspot.com",
    messagingSenderId: "868951047151",
    appId: "1:868951047151:web:fc57fd8939f2e414a0141a",
    measurementId: "G-ZZE9S2H8VF"
  };

  const app = initializeApp (firebaseConfig);
  export const db = getFirestore (app);