import React from 'react'

type Props = {
    title: string,
    isDarkMode: boolean
}

const DrawerItem = (props: Props) => {

    const style = {
        color: props.isDarkMode ? "white": "black",
        paddingLeft: "30%"
    };

  return (
    <div style={style}>
        <h4>{props.title}</h4>
    </div>
  )
}

export default DrawerItem