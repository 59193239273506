import React, { useState } from 'react'
import { IconType } from 'react-icons';
import { FaHome } from 'react-icons/fa';
import { useThemeStore } from '../../stores/themeStore';

type Props = {
    icon: IconType,
    title: string
    text: string
}

const DropDownItem = (props: Props) => {

    const [isHovering, setIsHovering] = useState(false);
    const {isDarkMode} = useThemeStore ();

    function handleIsHovering(): void {
        setIsHovering(true);
    }

    function handleNotHovering(): void {
        setIsHovering(false);
    }

    const style: any = {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "start",
        margin: "10px",
        padding: "10px", // increased padding for better look
        backgroundColor: isHovering 
        ? (isDarkMode ? "#3a3a3a" : "#f0f0f0")  // dark grey for dark mode, very light grey for light mode
        : "transparent", // light grey background on hover
        borderRadius: "10px", // soft rounded corners
        cursor: "pointer", // cursor change for better UX
        transition: "background-color 0.3s ease" //
    };

    const col: any = {
        display: "flex",
        flexDirection: "column",
        color: "white",
        paddingLeft: "20px",
        paddingRight: "20px",
    };

    const iconWrapper: any = {
        backgroundColor: !isHovering ? "#3a3a3a" : "lightGrey",
        borderRadius: "15%",
        padding: "10px",
        display: "flex",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", 
    };

    return (
        <div style={style} onMouseEnter={handleIsHovering} onMouseLeave={handleNotHovering}>
           <div style={iconWrapper}> {React.createElement(props.icon, { size: 30, color: isHovering ? "#2290ff" : "white" })} </div>
            <div style={col}>
                <h3 style={{ margin: "0", color: isDarkMode ? "white": "black"}}>{props.title}</h3>
                <p style={{color: isDarkMode ? "white": "black"}}>{props.text}</p>
            </div>
        </div>
    )
}

export default DropDownItem