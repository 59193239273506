import React, { useState } from 'react'
import { ProjectModel } from '../../models/ProjectModel'
import ThemeWrapper from '../../common/ThemeWrapper'
import Header from '../../shared/header/Header'
import Column from '../../common/Column'
import Container from '../../common/Container'
import { useLocation } from 'react-router-dom'
import Row from '../../common/Row'
import Card from '../../common/Card'

type Props = {
}

const ProjectDetailsPage = (props: Props) => {

    const location = useLocation();
    const data = location.state as ProjectModel;
    const [firstSentence, ...bulletPoints] = data.description.split ('•');
    const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? data.images.length - 1 : prevIndex - 1));
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === data.images.length - 1 ? 0 : prevIndex + 1));
  };

    return <div>
        <ThemeWrapper>
            <Column >
                <Header></Header>
                <Container >
                    <Column alignItems='start' marginLeft='15vw'>
                        <h3>{data.title}</h3>
                        <Row justifyConent='space-between'>
                            <img style={{ height: "80vh" }} src={data.images[currentIndex]}></img>
                            <Container width='40vw' height='80vh' margin='0 10vw'>
                                <Card>
                                    <p style={{ fontSize: "18px", padding: "15px", marginLeft: "10px" }}>{firstSentence}</p>
                                    <ul>
                                        {bulletPoints.map((item, index) => (
                                            <li style={{ fontSize: "18px", padding: "15px" }} key={index}>{item.trim()}</li> // Trim to remove any extra spaces
                                        ))}
                                    </ul>
                                </Card>
                            </Container>
                        </Row>
                        <Container width='69.4vw'>
                        <Row justifyConent='space-between'>
                        {data.images.map ((image, idx) => (<img src={image} style={{height: "20vh", paddingTop: "20px"}} onClick={() => {setCurrentIndex (idx)}}></img>))}
                        </Row>
                        </Container>
                    </Column>
                </Container>
            </Column>
        </ThemeWrapper>
    </div>
}

export default ProjectDetailsPage