import React from 'react'
import { useMediaQuery } from 'react-responsive';
import TypingAnimator from 'react-typing-animator';
import { useThemeStore } from '../stores/themeStore';

type Props = {}

const TypingText = (props: Props) => {
    const textArray = ['Muhammad Khan Awan', 'The Flutter Fanatic', '4 Plus Years of Experience', 'Worked on 20+ Apps'];
    const isMobile = useMediaQuery({ maxWidth: 1100});
    const {isDarkMode} = useThemeStore ();

  return (
    <div style={{marginLeft: isMobile ? "3vw" : "15vw"}}>
        <TypingAnimator
          textArray={textArray}
          cursorColor="#333"
          textColor= {isDarkMode ? "white": "black"}
          fontSize="30px"
          typingSpeed={60}
          delaySpeed={1000}
          height={'30px'}
          backspace loop={true}    />
  
    </div>
  );
}

export default TypingText