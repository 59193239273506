import React from 'react'
import HeaderItem from './HeaderItem';
import ThemeButton from './ThemeButton';
import HomeButton from './HomeButton';
import { useMediaQuery } from 'react-responsive';
import MobileHeader from './MobileHeader';

type Props = {
}

const Header = (props: Props) => {


  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1199 });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  const isDesktop = useMediaQuery({ minWidth: 1024 });

  const style: any = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    margin: "0 15vw",
    boxSizing: "border-box",
    flexWrap: "wrap"
  };
  if (isMobile || (isTablet && isPortrait)) return <MobileHeader></MobileHeader>
  return (
    <div style={style}>
      {/* <div style={{ paddingLeft: "15vw" }}></div> */}
      <HeaderItem title='{ }' isBrackets={true} route='/'></HeaderItem>
      {/* <div style={{ paddingLeft: isTablet ? "10vw" : "15vw" }}></div> */}
      <HomeButton></HomeButton>
      <HeaderItem title='Projects' route='/projects'></HeaderItem>
      <a style={{textDecoration: "none"}} target="_blank" href='https://firebasestorage.googleapis.com/v0/b/portfolio-f7140.appspot.com/o/static%2Fkhan%20sr%20flutter%20dev.pdf?alt=media&token=e827ed94-44f1-4f02-9985-d3c1bf7b87e9'> <HeaderItem title='Resume'></HeaderItem> </a>
      <HeaderItem title='Artwork'></HeaderItem>
      <HeaderItem title='Contact'></HeaderItem>
      {/* <div style={{ paddingLeft: isTablet ? "2vw" : "8vw" }}></div> */}
      <ThemeButton></ThemeButton>
    </div>
  )
}

export default Header