import React from 'react'

type Props = {
    children: React.ReactNode,
    height?: string,
    width?: string,
    margin?: string,
    padding?: string,
    color?: string,
    backgroundColor?: string
}

const Container = (props: Props) => {
    const style: any = {
        height: props.height,
        width: props.width,
        margin: props.margin,
        padding: props.padding,
        color: props.color,
        backgroundColor: props.backgroundColor
    };
  return (
    <div style={style}>{props.children}</div>
  )
}

export default Container