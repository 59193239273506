import React from 'react'
import { useThemeStore } from '../../stores/themeStore';
import ProfileAvatar from './ProfileAvatar';
import AboutMeCard from './AboutMeCard';
import Card from '../../common/Card';
import Row from '../../common/Row';
import Skills from './Skills';
import Header from '../../shared/header/Header';
import Experice from './Experience';
import { useMediaQuery } from 'react-responsive';
import Column from '../../common/Column';
import Gif from '../../common/gif';
import TypingText from '../../common/TypingText';

export default function HomePage() {

  const {isDarkMode} = useThemeStore ();
  const isMobile = useMediaQuery({ maxWidth: 1100});
  const homePageStyle: any = {
    display: "flex",
    flexDirection: "column",
    backgroundColor: isDarkMode ? "#121212" : "white",
  };
  return (
    <div style={homePageStyle}>
      <Header></Header>
      <TypingText></TypingText>
      <ProfileAvatar></ProfileAvatar>
      {isMobile && <Column>
        <AboutMeCard></AboutMeCard>
        <Skills></Skills>
        </Column>}
      {!isMobile && <Row>
        <AboutMeCard></AboutMeCard>
        <Skills></Skills>
        </Row>}
        <Experice></Experice>
        <div style={{height: "5vh"}}></div>
        {/* <Gif></Gif> */}
        
    </div>
    
  )
}
