import { collection, getDocs } from "firebase/firestore";
import { WorkExperienceModel } from "../models/WorkExperienceModel"
import {create} from "zustand";
import { db } from "../util/FirebaseDb";

type WorkExperienceStore = {
    workExperiences: WorkExperienceModel [];
    getWorkExperiences: () => Promise <void>;
    isLoading: boolean;
}

export const useWorkExpreinceStore = create <WorkExperienceStore>((set) => ({
   
    workExperiences: [],
    isLoading: true,
    getWorkExperiences: async () => {
        console.log ("get work expriece method got called");
        const workExpCollection = collection (db, "workExp");
        const workExpSnapshot = await getDocs (workExpCollection);
        // const workExperiences = workExpSnapshot.docs.map (doc => doc.data () as WorkExperienceModel);
        const workExperiences = workExpSnapshot.docs.map(doc => {
            const data = doc.data();
            
            return {
                id: doc.id,
                orgName: data?.orgName ?? "",       // Use default empty string if undefined
                startEndDate: data?.startEndDate ?? "",
                projects: data?.projects ?? []
            } as WorkExperienceModel;
            
        });
        set ({workExperiences: workExperiences, isLoading: false});
    }
}));

