import React from 'react'
import { useThemeStore } from '../../stores/themeStore';
import DrawerItem from './DrawerItem';
import ThemeButton from './ThemeButton';

type Props = {
    isOpen: boolean
}

const Drawer = (props: Props) => {
  const {isDarkMode} = useThemeStore ();

    const componetStyle: any = {
        display: "flex",
        flexDirection: "column",
        width: "30vw",
        height: "100%",
        left: props.isOpen? "0": "-30vw",
        backgroundColor: isDarkMode ? "black": "white",
        position: "absolute",
    };
  return (
    <div style={componetStyle}>
      <DrawerItem title='Home' isDarkMode= {isDarkMode}></DrawerItem>
      <DrawerItem title='About' isDarkMode= {isDarkMode}></DrawerItem>
      <DrawerItem title='Contact' isDarkMode= {isDarkMode}></DrawerItem>
      <DrawerItem title='Projects' isDarkMode= {isDarkMode}></DrawerItem>
      <DrawerItem title='Resume' isDarkMode= {isDarkMode}></DrawerItem>
      <ThemeButton></ThemeButton>
    </div>
  )
}

export default Drawer