import React from 'react'
import { useThemeStore } from '../../stores/themeStore';
import { FaLinkedin, FaMedium, FaReddit, FaStackOverflow } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';

type Props = {}

const AboutMeCard = (props: Props) => {

    const { isDarkMode } = useThemeStore();
    const isMobile = useMediaQuery({ maxWidth: 1100});

    const style: any = {
        width: isMobile ? "90vw" : "30vw",
        display: "flex",
        flexDirection: "column",
        border: isDarkMode ? "1px solid white" : "1px solid lightGrey",
        borderRadius: "10px",
        marginLeft: isMobile ? "3vw" : "15vw",
        marginRight: isMobile ? "2vw" : "0vw",
        marginBottom: isMobile ? "3vh" : "10vh",
        padding: isMobile ? "10px" : "2vh 2vw",
        color: isDarkMode ? "white" : "black",
    };
    return (
        <div style={style}>
            <h2 style={{marginLeft: isMobile ? "5vw" : "0"}}>About Me</h2>
            <ul> 
                <li>Write super clean & organized, maintainable, loosely coupled, documented, readable, modular & scalable code using Flutter best practices, <strong>clean</strong> architecture, SOLID & DRY principles and DI</li>
                <li>Aggressive approach to problem solving</li>
                <li><strong> Google & Apple Pay </strong> and Online Credit Card Payment methods</li>
                <li>Proactive communication</li>
                <li> Comfortable with <strong> Git</strong>  and <strong> Github </strong> </li>
                <li> Prefer <strong> Bloc </strong> , but can work with any state management </li>
                <li> App Debugging </li>
                <li> API integrations </li>
                <li> Can work with <strong> Firebase </strong> , <strong> HTTP </strong> based backend or any other BAAS</li>
                <li> Can switch to any framework or language </li>
                <li> Comfortable with <strong> Code magic </strong> (for CI/CD)</li>
                <li> Super comfortable with <strong> google play console</strong>  and <strong> app store connect </strong>  for app deployments and
                pushing updates </li>
            </ul>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "10px 100px" }}>
                <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                    <FaLinkedin size={40} color={isDarkMode ? "white" : "black"} />
                </a>
                <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                    <FaReddit size={40} color={isDarkMode ? "white" : "black"} />

                </a>
                <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                    <FaStackOverflow size={40} color={isDarkMode ? "white" : "black"} />
                </a>
                <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                    <FaMedium size={40} color={isDarkMode ? "white" : "black"} />
                </a>
            </div>
        </div>
    )
}

export default AboutMeCard