import {create} from "zustand";
import { ProjectModel } from "../models/ProjectModel";
import {db} from "../util/FirebaseDb"
import { collection, getDocs } from "firebase/firestore";

type ProjectStore = {
    isLoading: boolean,
    projects: ProjectModel [],
    getProjects (): void
}

export const useProjectStore = create <ProjectStore> ((set) => ({
    isLoading: true,
    projects: [],
    getProjects: async () => {
        const projectsCollection = collection (db, "projects");
        const projectSnapshot = await getDocs (projectsCollection);
        const projects = projectSnapshot.docs.map (doc => {
            const data = doc.data ();
            return {
                id: doc.id,
                title: data.title ?? "",
                images: data.images ?? [],
                description: data.description
            } as ProjectModel;
        });
        set ({isLoading: false, projects: projects});
    }
}));