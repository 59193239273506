import React, { useState } from 'react'
import { FaCross, FaGripLines, FaWindowClose } from 'react-icons/fa'
import Drawer from './Drawer';
import { useThemeStore } from '../../stores/themeStore';

type Props = {
}

const MenuButton = (props: Props) => {
  const [isDrawerOpen, setDrawerOpen] = useState (false);
  const {isDarkMode} = useThemeStore ();


  function setIsOpentToFalse () {
    setDrawerOpen (false);
  }
  
  function setIsOpentToTrue () {
    setDrawerOpen (true);
  }


  if (isDrawerOpen) return <div> <FaWindowClose color={isDarkMode ? "white": "black"} size={24} onClick={setIsOpentToFalse}></FaWindowClose>
  {isDrawerOpen && (<Drawer isOpen= {isDrawerOpen}></Drawer>)}
   </div>
  console.log ("the value of is drawer open is: " + isDrawerOpen);
  return (
    <div>
      <FaGripLines color={isDarkMode ? "white": "black"} size={24} onClick={setIsOpentToTrue}></FaGripLines>
    </div>
  )
}

export default MenuButton