import { Link } from 'react-router-dom';
import { useThemeStore } from '../../stores/themeStore'
import { title } from 'process';

type Props = {
    title: string,
    isBrackets?: boolean,
    route?: string
}

const HeaderItem = (props: Props) => {
    const {isDarkMode} = useThemeStore ();

    const style: any = {
        color: isDarkMode ? "white" : "black",
        // paddingLeft: "2.5vw",
        // paddingRight: "2.5vw",
    };

    if (props.route) {
      return (
          <Link to={props.route} style={{ textDecoration: "none" }}>
              <h4 style={style}>{props.title}</h4>
          </Link>
      );
  }

    if (props.isBrackets) return <h2 style={{color: isDarkMode ? "white": "black"}}>{props.title}</h2>
  return ( 
     <h4 style={style}>{props.title}</h4> 
  )
}

export default HeaderItem