import React from 'react'
import { useThemeStore } from '../stores/themeStore';

type Props = {
    children: React.ReactNode,
    margin?: string,
    padding?: string,
}

const Card = (props: Props) => {
    const {isDarkMode} = useThemeStore ();

    const cardStyle : any = {
        border: isDarkMode ? "1px solid white": "1px solid lightGrey",
        borderRadius: "10px",
        color: isDarkMode ? "white": "black",
        margin: props.margin,
        display: "inline-block",
        padding: props.padding
    };
    
  return (
    <div style={cardStyle}>{props.children}</div>
  )
}

export default Card