import React from 'react';
import {FaSun, FaMoon} from "react-icons/fa";
import { useThemeStore } from '../../stores/themeStore';

type Props = {}

const ThemeButton = (props: Props) => {
    const {isDarkMode, toggleTheme} = useThemeStore ();
    return (
        <button
          onClick={toggleTheme}
          style={{
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
          }}
        >
          {isDarkMode ? <FaMoon color="#FFF" size={24} /> : <FaSun color="black" size={24} />}
        </button>
      );
}

export default ThemeButton