import React from 'react'
import { FaGripLines, FaHamburger } from 'react-icons/fa'
import MenuButton from './MenuButton';
import { useThemeStore } from '../../stores/themeStore';

type Props = {}

const MobileHeader = (props: Props) => {
  const {isDarkMode} = useThemeStore ();

    const componentStyle: any = {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: "3vw",
        paddingRight: "3vw",

    };
    
  return (
    <div style={componentStyle}>
        <h2 style={{color: isDarkMode ? "white" : "black"}}> {"{}"}</h2>
        <div style={{display: "flex", flexDirection: "column", margin: "0"}}>
        <MenuButton></MenuButton>
        </div>
    </div>
  )
}

export default MobileHeader