import React from 'react'
import Column from '../../common/Column'
import Container from '../../common/Container'
import Card from '../../common/Card'
import ColoredSkill from './ColoredSkill'
import { useMediaQuery } from 'react-responsive'

type Props = {}

const Skills = (props: Props) => {
    type Skill = {
        title: string,
        color: string
    };

    const isMobile = useMediaQuery({ maxWidth: 1100});

    const skills: Skill[] = [

        { title: 'Flutter', color: '#02569B' },
        { title: 'Bloc', color: '#087E8B' },
        { title: 'Firebase', color: '#FFCA28' },
        { title: 'React', color: '#61DAFB' },
        { title: 'NodeJS', color: '#339933 ' },
        { title: 'Express', color: '#404040 ' },
        { title: 'MongoDB', color: '#47A248' },
        { title: 'AWS', color: '#FF9900' },
        { title: 'Spring Boot', color: '#6DB33F' },
        { title: 'Git', color: '#F05032' },
        { title: 'Github', color: 'grey' },
        { title: 'NestJS', color: '#E0234E' },
        { title: 'App Store Connect', color: '#0D96F6' },
        { title: 'Google Play Console', color: '#3DDC84 '},
        { title: 'Code Magic', color: '#45D1FD '},
    ];

    return (
        <div>
            <Card margin={isMobile ? "0 2vw 3vh 3vw" : '0 40px'}>
                <Column>
                    <Container
                        width={isMobile ? "90vw" : '30vw'}
                        padding={isMobile ? "10px" : '30px'}
                        >
                            <div>
                                <h2 >Skills</h2>
                                <p>
                                    As a versatile software engeneer, I've gained proficiency in a wide range of skills that encompass both mobile app and back-end development. These skills have not only been critical in my growth as a developer, but they also enable me to bring diverse and rich functionalities to the projects I handle. I've also had the opportunity to work with Nest JS and Spring Boot, thereby broadening my skills into backend development. All these skills coalesce to form a comprehensive toolset, empowering me to create holistic digital solutions that balance functionality, aesthetics, and performance.
                                    I'm also familiar with React, Express, Nest JS, AWS Basics, Cpanel Basics, Nginx, Linux, Asp.Net, Java Spring Boot
                                </p>
                                {skills.map((skill, index) => (
                                    <ColoredSkill key={index} backgroundColor={skill.color} title={skill.title} />
                                ))}
                            </div>
                        </Container>

                </Column>
            </Card>

        </div>
    )
}

export default Skills