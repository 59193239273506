import React from 'react'
import Card from '../../common/Card'
import Container from '../../common/Container'
import Column from '../../common/Column'
import { ProjectModel } from '../../models/ProjectModel'
import { useNavigate } from 'react-router-dom';

type Props = {
  project: ProjectModel
}

const ProjectThumbnail = (props: Props) => {
  const navigate = useNavigate ();

   function handleOnClick(): void {
    navigate ("/projectDetails", {state: props.project});
  }

  const truncate = (str: string, length: number) => {
    return str.length > length ? str.substring(0, length) + '...' : str;
  };

  return (
    <div style={{padding: "30px", cursor: "pointer"}} onClick={handleOnClick}>
      <Card>
        <Column>
    
        <img src={props.project?.images[0]} style={{height: "40vh", width: "22vw", margin: "20px", borderRadius: "10px", objectFit: "cover", background: 'rgba(39,62,84,0.82)'}} ></img>

        <h2 style={{paddingLeft: "20px", margin: "0"}}>{props.project?.title}</h2>
        <p style={{width: "22vw", paddingLeft: "20px"}}>{truncate(props.project?.description, 100)}</p>
        </Column>
      </Card>
    </div>
  )
}

export default ProjectThumbnail